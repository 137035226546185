const dataAllProducts = [
  /* {
    id: "пицца-1",
    category: "пицца",
    title: "Чесночная",
    descr: `сыр "Моцарелла", копченое куриное филе халяль, томаты, томатный соус, кунжут, чесночный соус`,
    price: 420,
    weight: "",
  },
  {
    id: "пицца-2",
    category: "пицца",
    title: "Цезарь",
    descr: `сыр "Моцарелла", куриное филе халяль, листья салата ,оригинальный соус "Цезарь", сыр "Пармезан", томатный соус`,
    price: 450,
    weight: "",
  },
  {
    id: "пицца-3",
    category: "пицца",
    title: "Ди-Поло",
    descr: `сыр "Моцарелла", куриное филе халяль, шампиньоны, тушеные в сливках, томаты, маслины, томатный соус`,
    price: 470,
    weight: "",
  },
  {
    id: "пицца-4",
    category: "пицца",
    title: "Сезон охоты",
    descr: `сыр "Моцарелла", охотничьи колбаски, томаты, опята маринованные, маслины, томатный соус`,
    price: 480,
    weight: "",
  },
  {
    id: "пицца-5",
    category: "пицца",
    title: "Мексиканская",
    descr: `сыр "Моцарелла", охотничьи колбаски, перец болгарский, пепперони, томаты, томатный соус (острота на выбор)`,
    price: 460,
    weight: "",
  },
  {
    id: "пицца-6",
    category: "пицца",
    title: "Скандинава",
    descr: `сыр "Моцарелла", норвежский лосось, листья салата, тигровые креветки, помидорки черри, творожный сыр`,
    price: 520,
    weight: "",
  },
  {
    id: "пицца-7",
    category: "пицца",
    title: "Пепперони",
    descr: `сыр "Моцарелла", томатный соус, томаты, пепперони, сыр "Пармезан"`,
    price: 430,
    weight: "",
  },
  {
    id: "пицца-8",
    category: "пицца",
    title: "Маргарита",
    descr: `сыр "Моцарелла", томатный соус, томаты, оливковое масло`,
    price: 400,
    weight: "",
  },
  {
    id: "пицца-9",
    category: "пицца",
    title: "Гавайская",
    descr: `сыр "Моцарелла", фирменный соус "Цезарь", копченое куриное филе, ананасы`,
    price: 430,
    weight: "",
  },
  {
    id: "пицца-10",
    category: "пицца",
    title: "Мясная",
    descr: `сыр  "Моцарелла", томатный  соус , томаты, копченое куриное филе, пепперони, охотничьи колбаски`,
    price: 510,
    weight: "",
  },
  {
    id: "холодные-роллы-1",
    category: "холодные-роллы",
    title: "Филадельфия Классическая",
    descr: `рис, норвежский лосось, сливочный сыр, авокадо, нори`,
    price: 340,
    weight: "",
  },
  {
    id: "холодные-роллы-2",
    category: "холодные-роллы",
    title: "Филадельфия Лайт",
    descr: `рис, норвежский лосось, сливочный сыр, огурец, икра "Macaro", нори`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-3",
    category: "холодные-роллы",
    title: "Филадельфия Люкс",
    descr: `рис, норвежский лосось, творожный сыр, икра красная, нори`,
    price: 360,
    weight: "",
  },
  {
    id: "холодные-роллы-4",
    category: "холодные-роллы",
    title: "Ниссин",
    descr: `рис, норвежский лосось, сливочный сыр, огурец, икра "Macaro", нори, фирменная шапочка`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-5",
    category: "холодные-роллы",
    title: "Сакура",
    descr: `рис, творожный сыр, копченое куриное филе халяль, листья салата, огурец, кунжут, нори, фирменная шапочка`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-6",
    category: "холодные-роллы",
    title: "Овощной",
    descr: `рис, листья салата ,перец болгарский, огурец, томаты, авокадо, нори`,
    price: 300,
    weight: "",
  },
  {
    id: "холодные-роллы-7",
    category: "холодные-роллы",
    title: "Калифорния",
    descr: `рис, тигровые креветки, сливочный сыр, огурец, авокадо, икра "Macaro", нори`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-8",
    category: "холодные-роллы",
    title: "Цезарь с курицей",
    descr: `рис, томаты, куриное филе халяль, листья салата, сыр "Пармезан", соус "Цезарь", нори`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-9",
    category: "холодные-роллы",
    title: "Цезарь с тигровыми креветками",
    descr: `рис, томаты, тигровые креветки, листья салата, сыр "Пармезан", соус "Цезарь", нори`,
    price: 350,
    weight: "",
  },
  {
    id: "холодные-роллы-10",
    category: "холодные-роллы",
    title: "Чипс ролл",
    descr: `рис, копченое куриное филе , огурец, томаты, чипсы, нори`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-11",
    category: "холодные-роллы",
    title: "Сливочный с креветкой",
    descr: `рис, тигровые креветки, сливочный сыр, авокадо, нори`,
    price: 310,
    weight: "",
  },
  {
    id: "запеченные-роллы-1",
    category: "запеченные-роллы",
    title: "Со снежным крабом",
    descr: `рис, икра "Масаrа", авокадо, снежный краб в остром соусе "Спайси", сыр "Мацарелла", фирменная шапочка`,
    price: 340,
    weight: "",
  },
  {
    id: "запеченные-роллы-2",
    category: "запеченные-роллы",
    title: "С копченой курицей",
    descr: `рис, творожный сыр, копченое куриное филе халяль, листья салата, кунжут, фирменная шапочка, соус "Терияки"`,
    price: 310,
    weight: "",
  },
  {
    id: "запеченные-роллы-3",
    category: "запеченные-роллы",
    title: "Премиум",
    descr: `рис, сливочный сыр, авокадо, норвежский лосось в кисло-соленом соусе, сыр "Мацарелла", икра красная`,
    price: 370,
    weight: "",
  },
  {
    id: "запеченные-роллы-5",
    category: "запеченные-роллы",
    title: "Феникс",
    descr: `рис, икра "Масаrа", куриное филе, сливочный сыр, снежный краб, сыр "Мацарелла", соус "Спайси"`,
    price: 340,
    weight: "",
  },
  {
    id: "запеченные-роллы-6",
    category: "запеченные-роллы",
    title: "С креветкой",
    descr: `рис, икра " Масаrа", сыр сливочный, авокадо, огурец, сыр "Мацарелла", тигровые креветки, соус "Спайси", соус "Унаги" `,
    price: 360,
    weight: "",
  },
  {
    id: "запеченные-роллы-7",
    category: "запеченные-роллы",
    title: "С тунцом",
    descr: `рис, тунец, авокадо, огурец, икра "Масаrа", лук зеленый, фирменная шапочка`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-1",
    category: "горячие-роллы",
    title: "Скандинава",
    descr: `рис, норвежский лосось, сливочный сыр, авокадо, темпурный кляр, соус "Унаги", соус "Спайси"`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-2",
    category: "горячие-роллы",
    title: "Нэко",
    descr: `рис, снежный краб, сливочный сыр, огурец, темпурный кляр, соус "Унаги", соус "Спайси"`,
    price: 330,
    weight: "",
  },
  {
    id: "горячие-роллы-5",
    category: "горячие-роллы",
    title: "Горячий с креветкой",
    descr: `рис, тигровые креветки, сливочный сыр, авокадо, огурец, темпурный кляр, соус "Спайси"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-6",
    category: "горячие-роллы",
    title: "Горячий с курицей",
    descr: `рис, куриное филе , огурец, соус "Спайси", темпурный кляр`,
    price: 320,
    weight: "",
  },
  {
    id: "горячие-роллы-7",
    category: "горячие-роллы",
    title: "Цезарь в темпуре с курицей",
    descr: `рис, томат, листья салата, куриное филе Халяль, темпурный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-8",
    category: "горячие-роллы",
    title: "Цезарь в темпере с  тигровыми креветками",
    descr: `рис, томат, листья салата, тигровые креветки, темперный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-9",
    category: "горячие-роллы",
    title: "Кентуки",
    descr: `рис, копченое куриное филе, сыр "Мацарелла", болгарский перец, темперный кляр, соус "Цезарь", соус "Терияки"`,
    price: 330,
    weight: "",
  },
  {
    id: "горячие-роллы-10",
    category: "горячие-роллы",
    title: "Сяомин",
    descr: `рис, снежный краб, творожный сыр, куриное филе, темпурный кляр, соус "Спайси", икра "Масаro"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-11",
    category: "горячие-роллы",
    title: "Магуро",
    descr: `рис, тунец, сливочный сыр, авокадо, зеленый лук, темпурный кляр, кунжут, соус "Унаги"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-12",
    category: "горячие-роллы",
    title: "Мацудо",
    descr: `рис, норвежский лосось, тигровые креветки, сливочный сыр, снежный краб, темперный кляр, соус "Унаги", майонез, кунжут`,
    price: 370,
    weight: "",
  },
  {
    id: "горячие-роллы-13",
    category: "горячие-роллы",
    title: "Калипсо",
    descr: `рис, норвежский лосось, сливочный сыр, тигровые креветки, зеленый лук, соус "Унаги"`,
    price: 360,
    weight: "",
  },
  {
    id: "горячие-роллы-14",
    category: "горячие-роллы",
    title: "Цезарь в темпуре с  лососем",
    descr: `рис, огурец, листья салата, норвежский лосось, темперный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 340,
    weight: "",
  },
  {
    id: "мини-роллы-1",
    category: "мини-роллы",
    title: "С огурцом",
    descr: `рис, огурец, нори`,
    price: 120,
    weight: "",
  },
  {
    id: "мини-роллы-2",
    category: "мини-роллы",
    title: "С лососем",
    descr: `рис, норвежский лосось, нори`,
    price: 180,
    weight: "",
  },
  {
    id: "мини-роллы-3",
    category: "мини-роллы",
    title: "С креветкой",
    descr: `рис, тигровые креветки, нори`,
    price: 190,
    weight: "",
  },
  {
    id: "мини-роллы-4",
    category: "мини-роллы",
    title: "С авокадо",
    descr: `рис, авокадо, нори`,
    price: 140,
    weight: "",
  },
  {
    id: "мини-роллы-5",
    category: "мини-роллы",
    title: "С курицей",
    descr: `рис, куриное филе, нори`,
    price: 150,
    weight: "",
  },
  {
    id: "салаты-1",
    category: "салаты",
    title: "Греческий",
    descr: `томаты, болгарский перец, огурец, листья салата , творожный сыр, оливковое масло, маслины`,
    price: 180,
    weight: "",
  },
  {
    id: "салаты-2",
    category: "салаты",
    title: "Цезарь",
    descr: `листья салата , оригинальный соус "Цезарь", гренки, сыр "Пармезан", помидоры черри, копченое куриное филе`,
    price: 220,
    weight: "",
  },
  {
    id: "салаты-3",
    category: "салаты",
    title: "Терияки",
    descr: `огурцы, помидоры, листья салата , куриное филе, соус "Терияки", кунжут`,
    price: 200,
    weight: "",
  },
  {
    id: "фастфуд-1",
    category: "фастфуд",
    title: "Картофель ФРИ",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-2",
    category: "фастфуд",
    title: "Картофель по-деревенски",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-3",
    category: "фастфуд",
    title: "Наггетсы (6 шт.)",
    descr: ``,
    price: 130,
    weight: "",
  },
  {
    id: "фастфуд-4",
    category: "фастфуд",
    title: "Соус томатный",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-5",
    category: "фастфуд",
    title: "Соус сырный",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-6",
    category: "фастфуд",
    title: "Соус цезарь",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-7",
    category: "фастфуд",
    title: `Острый "Спайси"`,
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-8",
    category: "фастфуд",
    title: "Хот-дог",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-9",
    category: "фастфуд",
    title: "Хот-дог с сыром и томатами",
    descr: ``,
    price: 140,
    weight: "",
  },
  {
    id: "фастфуд-10",
    category: "фастфуд",
    title: "Сэндвич 'Цезарь'",
    descr: `сэндвичный хлеб, соус цезарь, листья салата, ветчина куриная халяль, томаты, плавленый сыр`,
    price: 170,
    weight: "",
  },
  {
    id: "десерты-1",
    category: "десерты",
    title: "Чизкейк",
    descr: ``,
    price: 130,
    weight: "100 гр",
  },
  {
    id: "десерты-4",
    category: "десерты",
    title: "Мороженое",
    descr: ``,
    price: 70,
    weight: "40 гр (1 шарик)",
  },
  {
    id: "десерты-5",
    category: "десерты",
    title: "Фруктовый салат",
    descr: `банан, апельсин, яблоко, груша, киви, йогурт`,
    price: 140,
    weight: "140 гр",
  },
  {
    id: "кофе-1",
    category: "кофе",
    title: "Эспрессо",
    descr: `крепкий кофе, приготовленный из хорошо обжаренных и тонко помолотых зерен кофе`,
    price: 100,
    weight: "30 мл",
  },
  {
    id: "кофе-2",
    category: "кофе",
    title: "Американо",
    descr: ``,
    price: 120,
    weight: "120 мл",
  },
  {
    id: "кофе-3",
    category: "кофе",
    title: "Капучино",
    descr: ``,
    price: 150,
    weight: "200 мл",
  },
  {
    id: "кофе-4",
    category: "кофе",
    title: "Латте Классический",
    descr: `напиток на основе эспрессо с добавлением вспененного молока`,
    price: 150,
    weight: "200 мл",
  },
  {
    id: "кофе-5",
    category: "кофе",
    title: "Латте с сиропом",
    descr: `напиток на основе эспрессо с добавлением вспененного молока, сироп на выбор`,
    price: 160,
    weight: "200 мл",
  },
  {
    id: "кофе-6",
    category: "кофе",
    title: "Кофе по-Венски",
    descr: `кофе со взбитыми сливками`,
    price: 140,
    weight: "150 мл",
  },
  {
    id: "кофе-7",
    category: "кофе",
    title: "Гляссе",
    descr: ``,
    price: 180,
    weight: "150 мл",
  },
  {
    id: "кофе-8",
    category: "кофе",
    title: "Какао",
    descr: `подается с маршмеллоу`,
    price: 100,
    weight: "200 мл",
  },
  {
    id: "коктейли-молочные-1",
    category: "коктейли-молочные",
    title: "Фирменный Skandilove",
    descr: `ванильное мороженое, банан, молоко, клубничный сироп, взбитые сливки`,
    price: 180,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-2",
    category: "коктейли-молочные",
    title: "Тропический",
    descr: `ананасовый сок, молоко, кокосовый сироп, ванильное мороженое, взбитые сливки`,
    price: 180,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-3",
    category: "коктейли-молочные",
    title: "Клубничный",
    descr: `клубника, молоко, ванильное мороженое, клубничный сироп`,
    price: 170,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-4",
    category: "коктейли-молочные",
    title: "Ванильный",
    descr: `ванильное мороженое, молоко, ваниль`,
    price: 140,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-5",
    category: "коктейли-молочные",
    title: "Шоколадный",
    descr: `ванильное мороженое, банан, какао, молоко`,
    price: 150,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-6",
    category: "коктейли-молочные",
    title: "Бабл Гам",
    descr: `ванильное мороженое, молоко, сироп, маршмеллоу`,
    price: 160,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-7",
    category: "коктейли-молочные",
    title: "Классический",
    descr: `фруктовый сок, ванильное мороженое`,
    price: 100,
    weight: "200 мл",
  },
  {
    id: "смузи-1",
    category: "смузи",
    title: "Апельсиновый",
    descr: `банан, апельсин, апельсиновый сок`,
    price: 120,
    weight: "200 мл",
  },
  {
    id: "смузи-2",
    category: "смузи",
    title: "Клубничный",
    descr: `клубника ,банан, апельсиновый сок`,
    price: 140,
    weight: "200 мл",
  },
  {
    id: "фирменные-напитки-1",
    category: "фирменные-напитки",
    title: "Фламинго",
    descr: `газированная вода, лайм, мята, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-2",
    category: "фирменные-напитки",
    title: "Персиковая жвачка",
    descr: `газированная вода, персиковый сок, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-3",
    category: "фирменные-напитки",
    title: "Лимонад лимонный ",
    descr: `газированная вода, лимон, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-4",
    category: "фирменные-напитки",
    title: "Лимонад апельсиновый",
    descr: `газированная вода,апельсин,сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-5",
    category: "фирменные-напитки",
    title: "Мохито",
    descr: `газированная вода, лайм, мята, сироп`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-6",
    category: "фирменные-напитки",
    title: "Мохито клубничный",
    descr: `газированная вода, клубника, лайм, мята, сироп`,
    price: 125,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-7",
    category: "фирменные-напитки",
    title: "Свежесть лета",
    descr: `содовая вода, яблочный сок, сироп, киви, мята`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-8",
    category: "фирменные-напитки",
    title: "Лимонад с мармеладом",
    descr: `сок Добрый лимон-лайм, апельсиновый сок, свежий апельсин, мармеладки`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "холодные-напитки-1",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 60,
    weight: "0,33 л",
  },
  {
    id: "холодные-напитки-3",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 80,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-4",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 110,
    weight: "1 л",
  },
  {
    id: "холодные-напитки-5",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 160,
    weight: "1,5 л",
  },
  {
    id: "холодные-напитки-7",
    category: "холодные-напитки",
    title: "Сок Добрый (в ассортименте)",
    descr: ``,
    price: 170,
    weight: "1 л",
  },
  {
    id: "холодные-напитки-9",
    category: "холодные-напитки",
    title: "Вon Aqua",
    descr: `газированная`,
    price: 50,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-10",
    category: "холодные-напитки",
    title: "Вon Aqua",
    descr: `не газированная`,
    price: 50,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-11",
    category: "холодные-напитки",
    title: "Сок в ассортименте",
    descr: ``,
    price: 50,
    weight: "200 мл",
  }, */

  /* {
    id: "фастфуд-11",
    category: "фастфуд",
    title: "",
    descr: ``,
    price: 0,
    weight: "",
  }, */

  {
    goods: [
      {
        id: 1,
        category: "пицца",
        title: "Чесночная",
        descr:
          'сыр " Моцарелла", копченое куриное филе халяль, томаты, томатный соус, кунжут, чесночный соус',
        price: 570,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 2,
        category: "пицца",
        title: "Цезарь",
        descr:
          'сыр "Моцарелла", куриное филе халяль, листья салата ,оригинальный соус" Цезарь", сыр" Пармезан", томатный соус',
        price: 610,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 3,
        category: "пицца",
        title: "Ди-Поло",
        descr:
          'сыр "Моцарелла", куриное филе халяль, шампиньоны , тушеные в сливках, томаты, маслины, томатный соус',
        price: 630,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 4,
        category: "пицца",
        title: "Сезон охоты",
        descr:
          'сыр "Моцарелла", охотничьи колбаски, томаты, опята маринованные, маслины, томатный соус',
        price: 620,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 5,
        category: "пицца",
        title: "Мексиканская",
        descr:
          'ХХХ: сыр "Моцарелла", охотничьи колбаски, перец болгарский, пепперони, томаты, томатный соус (острота на выбор)',
        price: 610,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 6,
        category: "пицца",
        title: "Скандинава",
        descr:
          'сыр "Моцарелла", норвежский лосось, листья салата, тигровые креветки, помидорки черри, творожный сыр',
        price: 700,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 7,
        category: "пицца",
        title: "Пепперони",
        descr:
          'сыр  "Моцарелла", томатный соус, томаты ,пепперони, сыр "Пармезан"',
        price: 610,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 8,
        category: "пицца",
        title: "Маргарита",
        descr: 'сыр  "Моцарелла", томатный соус, томаты ,оливковое масло',
        price: 550,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 9,
        category: "пицца",
        title: "Гавайская",
        descr:
          'сыр  "Моцарелла", фирменный соус " Цезарь", копченое куриное филе , ананасы',
        price: 590,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 10,
        category: "пицца",
        title: "Мясная",
        descr:
          'сыр  "Моцарелла", томатный  соус , томаты, копченое куриное филе, пепперони, охотничьи колбаски',
        price: 690,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 11,
        category: "пицца",
        title: "Деревенская",
        descr:
          'сыр "Моцарелла", пицца соус с базиликом, томаты, копченое куриное филе, корнишоны, майонез',
        price: 570,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 12,
        category: "пицца",
        title: "Барбекю",
        descr:
          'сыр "Моцарелла", пицца соус с базиликом, томаты, пепперони, охотничьи колбаски, халапенью, соус барбекю',
        price: 600,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 13,
        category: "пицца",
        title: "Четыре сыра",
        descr:
          'Фрименный белый соус, сыр "Моцарелла", сыр Хохланд, творожный сыр, пармезан',
        price: 550,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 14,
        category: "пицца",
        title: '"4 сезона"',
        descr:
          'сыр "Моцарелла", пицца соус с базиликом, шампиньоны, копчене куриное филе, пепперони, охотничьи колбаски',
        price: 660,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 15,
        category: "холодные-роллы",
        title: "Филадельфия Классическая",
        descr: "рис, норвежский лосось, сливочный сыр, авокадо, нори",
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 16,
        category: "холодные-роллы",
        title: "Филадельфия  Лайт",
        descr: "рис, норвежский лосось, сливочный сыр, нори",
        price: 360,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 17,
        category: "холодные-роллы",
        title: "Филадельфия Люкс",
        descr: "рис, норвежский лосось, творожный сыр, икра красная, нори",
        price: 400,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 18,
        category: "холодные-роллы",
        title: "Ниссин",
        descr:
          'рис, норвежский лосось, сливочный сыр, огурец, икра " Macaro", нори, фирменная шапочка',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 19,
        category: "холодные-роллы",
        title: "Сакура",
        descr:
          "рис, творожный сыр,  копченое куриное филе халяль, листья салата ,  огурец, кунжут, нори, фирменная шапочка",
        price: 350,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 20,
        category: "холодные-роллы",
        title: "Овощной ",
        descr:
          "рис, листья салата ,перец болгарский, огурец, томаты, авокадо, нори",
        price: 340,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 21,
        category: "холодные-роллы",
        title: "Калифорния",
        descr:
          'рис,  тигровые креветки, сливочный сыр, огурец, авокадо,  икра " Macaro",нори',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 22,
        category: "холодные-роллы",
        title: "Цезарь  с курицей",
        descr:
          'рис, томаты, куриное филе халяль, листья салата , сыр "Пармезан",                    соус "Цезарь", нори',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 23,
        category: "холодные-роллы",
        title: "Цезарь  с  тигровыми креветками",
        descr:
          'рис, томаты,  тигровые креветки, листья салата , сыр "Пармезан",                    соус "Цезарь", нори',
        price: 390,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 24,
        category: "холодные-роллы",
        title: "Чипс ролл",
        descr: "рис, копченое куриное филе ,  огурец, томаты, чипсы, нори",
        price: 360,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 25,
        category: "холодные-роллы",
        title: "Сливочный с креветкой",
        descr: "рис,  тигровые креветки, сливочный сыр, авокадо, нори",
        price: 360,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 26,
        category: "запеченные-роллы",
        title: "С копченой курицей",
        descr:
          'рис, творожный сыр,  копченое куриное филе халяль, листья салата, кунжут, фирменная шапочка, соус " Терияки"',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 27,
        category: "запеченные-роллы",
        title: "Со снежным крабом",
        descr:
          'рис,  икра  " Масаrа", авокадо, снежный краб ,  фирменная шапочка,                                           соус " Унаги"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 28,
        category: "запеченные-роллы",
        title: "С креветкой",
        descr:
          'рис, икра " Масаrа", сыр сливочный, авокадо, огурец, сыр " Мацарелла" , тигровые креветки, соус " Спайси", соус " Унаги" ',
        price: 400,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 29,
        category: "запеченные-роллы",
        title: "С  тунцом",
        descr:
          'рис,   тунец, авокадо, огурец,  икра " Масаrа",  лук зеленый,                                                                      фирменная шапочка',
        price: 390,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 30,
        category: "запеченные-роллы",
        title: "Феникс",
        descr:
          'рис,  икра " Масаrа", куриное филе, сливочный сыр, снежный краб,                                                  фирменная шапочка, соус " Спайси"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 31,
        category: "запеченные-роллы",
        title: "Премиум",
        descr:
          'рис,  сливочный сыр, авокадо, норвежский лосось ,  соус " Спайси" ,                                               сыр " Мацарелла", икра красная',
        price: 420,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 32,
        category: "горячие-роллы",
        title: "Скандинава",
        descr:
          'рис, норвежский лосось,   сливочный сыр, авокадо, темперный кляр, соус "Унаги",                            соус " Спайси"',
        price: 390,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 33,
        category: "горячие-роллы",
        title: "Нэко",
        descr:
          'рис, снежный краб, сливочный сыр,  огурец,  темперный кляр, соус "Унаги",              соус " Спайси"',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 34,
        category: "горячие-роллы",
        title: "Горячий  с креветкой",
        descr:
          'рис, тигровые креветки, сливочный сыр, авокадо,  огурец, темперный кляр,                     соус " Спайси"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 35,
        category: "горячие-роллы",
        title: "Горячий с курицей",
        descr: 'рис,  куриное филе , огурец, соус " Спайси", темперный кляр',
        price: 360,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 36,
        category: "горячие-роллы",
        title: "Цезарь в темпере с курицей",
        descr:
          'рис,  томат, листья салата , куриное филе Халяль, темперный кляр,                                                       соус  "Цезарь", сыр "Пармезан"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 37,
        category: "горячие-роллы",
        title: "Цезарь в темпере с  тигровыми креветками",
        descr:
          'рис,  томат, листья салата ,   тигровые креветки, темперный кляр,                                                       соус  "Цезарь", сыр "Пармезан"',
        price: 390,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 38,
        category: "горячие-роллы",
        title: "Кентуки",
        descr:
          'рис,  копченое куриное филе ,  сыр " Мацарелла", болгарский перец,  темперный кляр, соус "Цезарь", соус " Терияки"',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 39,
        category: "горячие-роллы",
        title: "Сяомин",
        descr:
          'рис,   снежный краб, творожный сыр, куриное филе,   темпурный кляр,                                                  соус "Спайси", икра " Масаro"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 40,
        category: "горячие-роллы",
        title: "Магуро",
        descr:
          'рис,    тунец, сливочный сыр,  авокадо, зеленый лук, темперный кляр,  кунжут,                                      соус "Унаги"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 41,
        category: "горячие-роллы",
        title: "Саламандр",
        descr:
          'рис, норвежский лосось, тигровые креветки , сливочный сыр, снежный краб, темперный кляр, соус "Унаги", майонез, кунжут',
        price: 370,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 42,
        category: "горячие-роллы",
        title: "Калипсо",
        descr:
          'рис, норвежский лосось, , сливочный сыр, тигровые креветки, зеленый лук, соус "Унаги"',
        price: 390,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 43,
        category: "горячие-роллы",
        title: "Цезарь в темпуре с  лососем",
        descr:
          'рис,  огурец, листья салата , норвежский лосось, темперный кляр,                       соус  "Цезарь",  сыр "Пармезан"',
        price: 380,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 44,
        category: "мини-роллы",
        title: "С лососем",
        descr: "рис, норвежский лосось, нори",
        price: 250,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 45,
        category: "мини-роллы",
        title: "С креветкой",
        descr: "рис, тигровые креветки, нори",
        price: 240,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 46,
        category: "мини-роллы",
        title: "С курицей",
        descr: "рис, куриное филе, нори",
        price: 200,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 47,
        category: "мини-роллы",
        title: "С огурцом",
        descr: "рис, огурец, нори",
        price: 180,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 48,
        category: "мини-роллы",
        title: "С авокадо",
        descr: "рис, авокадо, нори",
        price: 190,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 49,
        category: "салаты",
        title: "ГРЕЧЕСКИЙ",
        descr:
          "томаты, болгарский перец, огурец, листья салата , творожный сыр, оливковое масло, маслины",
        price: 280,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 50,
        category: "салаты",
        title: "ЦЕЗАРЬ",
        descr:
          'листья салата , оригинальный соус " Цезарь", гренки, сыр " Пармезан", помидоры черри, копченое куриное филе',
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 51,
        category: "салаты",
        title: "Терияки",
        descr:
          'огурцы, помидоры, болгарский перец,  листья салата , куриное филе, соус " Терияки", кунжут',
        price: 290,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 52,
        category: "фастфуд",
        title: "Картофель ФРИ",
        descr: "",
        price: 150,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 53,
        category: "фастфуд",
        title: "Картофель  по-деревенски",
        descr: "",
        price: 150,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 54,
        category: "фастфуд",
        title: "Наггетсы  (6 шт.)",
        descr: "",
        price: 170,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 55,
        category: "фастфуд",
        title: "Сырные палочки (5 шт.)",
        descr: "",
        price: 230,
        weight: "",
        image_url: "",
        show: "",
      },
      {
        id: 56,
        category: "фастфуд",
        title: "Соусы в ассортименте",
        descr: "томатный, сырный, цезарь, террияки, кисло-сладкий",
        price: 30,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 57,
        category: "фастфуд",
        title: "Хот-дог",
        descr: "",
        price: 140,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 58,
        category: "фастфуд",
        title: "Хот-дог с сыром и томатами",
        descr: "",
        price: 160,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 59,
        category: "фастфуд",
        title: 'Сэндвич " Цезарь"',
        descr:
          "сэндвичный хлеб, соус цезарь, листья салата ,  копченое куриное филе халяль, томаты, плавленый сыр",
        price: 220,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 60,
        category: "десерты",
        title: "Чизкейк ",
        descr: "( в ассортименте)",
        price: 160,
        weight: "100 гр",
        image_url: "",
        show: 1,
      },
      {
        id: "",
        category: "десерты",
        title: "",
        descr: "",
        price: "",
        weight: "",
        image_url: "",
        show: "",
      },
      {
        id: 61,
        category: "десерты",
        title: "Мороженое",
        descr:
          "( в ассортименте) украшается вафельным рожком, топпингом , посыпкой",
        price: 120,
        weight: "50 гр (1 шарик)",
        image_url: "",
        show: 1,
      },
      {
        id: 62,
        category: "десерты",
        title: "Фруктовый салат",
        descr: "ассорти из фруктов, шарик ванильного мороженого",
        price: 170,
        weight: "150 гр",
        image_url: "",
        show: 1,
      },
      {
        id: 63,
        category: "кофе",
        title: "Эспрессо",
        descr:
          "крепкий кофе, приготовленный из хорошо обжаренных и тонко  помолотых зерен кофе",
        price: 120,
        weight: "30 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 64,
        category: "кофе",
        title: "Американо ",
        descr: "",
        price: 160,
        weight: "150 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 65,
        category: "кофе",
        title: "Капучино",
        descr: "",
        price: 190,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 66,
        category: "кофе",
        title: "Латте Классический",
        descr: "напиток на основе эспрессо с добавлением вспененного молока",
        price: 190,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 67,
        category: "кофе",
        title: "Латте  с сиропом",
        descr:
          "напиток на основе эспрессо с добавлением вспененного молока, сироп на выбор",
        price: 210,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 68,
        category: "кофе",
        title: "Кофе по-Венски",
        descr: "кофе со взбитыми сливками",
        price: 210,
        weight: "150 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 69,
        category: "кофе",
        title: "Гляссе  ",
        descr: "кофе  с мороженым",
        price: 210,
        weight: "150 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 70,
        category: "кофе",
        title: "Какао",
        descr: "подается  с маршмеллоу",
        price: 190,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 71,
        category: "коктейли-молочные",
        title: "Фирменный Skandilove",
        descr:
          "ванильное мороженое, банан, молоко, клубничный сироп, взбитые сливки",
        price: 310,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 72,
        category: "коктейли-молочные",
        title: "Тропический",
        descr:
          "ананасовый сок, молоко, кокосовый сироп, ванильное мороженое, взбитые сливки",
        price: 310,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 73,
        category: "коктейли-молочные",
        title: "Клубничный",
        descr: "клубника, молоко, ванильное мороженое, клубничный сироп",
        price: 290,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 74,
        category: "коктейли-молочные",
        title: "Ванильный",
        descr: "ванильное мороженое, молоко, ваниль",
        price: 250,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 75,
        category: "коктейли-молочные",
        title: "Шоколадный",
        descr: "ванильное мороженое, банан, какао, молоко",
        price: 280,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 76,
        category: "коктейли-молочные",
        title: "Бабл  Гам",
        descr: "ванильное мороженое, молоко, сироп, маршмеллоу",
        price: 290,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 77,
        category: "коктейли-молочные",
        title: "Классический",
        descr: "фруктовый сок, ванильное мороженое",
        price: 180,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 78,
        category: "смузи",
        title: "Апельсиновый",
        descr: "банан, апельсин, апельсиновый сок",
        price: 250,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 79,
        category: "смузи",
        title: "Клубничный",
        descr: "клубника ,банан, апельсиновый сок",
        price: 250,
        weight: "200 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 80,
        category: "фирменные-напитки",
        title: "Фламинго",
        descr: "содовая , лайм, мята, сироп",
        price: 160,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 81,
        category: "фирменные-напитки",
        title: "Персиковая жвачка ",
        descr: "содовая, персиковый сок, сироп",
        price: 160,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 82,
        category: "фирменные-напитки",
        title: "Лимонад лимонный ",
        descr: "содовая, лимон, сироп",
        price: 160,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 83,
        category: "фирменные-напитки",
        title: "Лимонад апельсиновый",
        descr: "содовая, апельсин, сироп",
        price: 160,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 84,
        category: "фирменные-напитки",
        title: "Мохито",
        descr: "содовая, лайм, мята, сироп",
        price: "",
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 85,
        category: "фирменные-напитки",
        title: "Мохито клубничный ",
        descr: "содовая, клубника, лайм, мята, сироп",
        price: 125,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 86,
        category: "фирменные-напитки",
        title: "Свежесть лета   ",
        descr: "содовая вода, яблочный сок, сироп, киви, мята",
        price: 120,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 87,
        category: "фирменные-напитки",
        title: "Лимонад  с мармеладом",
        descr:
          "сок Добрый лимон-лайм, апельсиновый сок, свежий апельсин, мармеладки",
        price: 120,
        weight: "300 мл",
        image_url: "",
        show: 1,
      },
      {
        id: 88,
        category: "холодные-напитки",
        title: "Напитки ",
        descr: "Добрый Кола/Лимон-Лайм/ Апельсин/ Манго-Маракуйя ",
        price: 60,
        weight: "0,33 л",
        image_url: "",
        show: 1,
      },
      {
        id: 89,
        category: "холодные-напитки",
        title: "Напитки ",
        descr: "Добрый Кола/Лимон-Лайм/ Апельсин/ Манго-Маракуйя ",
        price: 80,
        weight: "0,5 л",
        image_url: "",
        show: 1,
      },
      {
        id: 90,
        category: "холодные-напитки",
        title: "Напитки ",
        descr: "Добрый Кола/Лимон-Лайм/ Апельсин/ Манго-Маракуйя ",
        price: 110,
        weight: "1 л",
        image_url: "",
        show: 1,
      },
      {
        id: 91,
        category: "холодные-напитки",
        title: "Напитки ",
        descr:
          "Добрый Кола/Ð¸Ð¼Ð¾Ð½-ÐÐ°Ð¹Ð¼/ ÐÐ¿ÐµÐ»ÑÑÐ¸Ð½/ ÐÐ°Ð½Ð³Ð¾-ÐÐ°ÑÐ°ÐºÑÐ¹Ñ ",
        price: 160,
        weight: "1,5 Ð»",
        image_url: "",
        show: 1,
      },
      {
        id: 92,
        category: "ÑÐ¾Ð»Ð¾Ð´Ð½ÑÐµ-Ð½Ð°Ð¿Ð¸ÑÐºÐ¸",
        title: "Ð¡Ð¾Ðº ÐÐ¾Ð±ÑÑÐ¹",
        descr: "(Ð² Ð°ÑÑÐ¾ÑÑÐ¸Ð¼ÐµÐ½ÑÐµ)",
        price: 170,
        weight: "1 Ð»",
        image_url: "",
        show: 1,
      },
      {
        id: 93,
        category: "ÑÐ¾Ð»Ð¾Ð´Ð½ÑÐµ-Ð½Ð°Ð¿Ð¸ÑÐºÐ¸",
        title: "Ðon Aqua",
        descr: "Ð³Ð°Ð·Ð¸ÑÐ¾Ð²Ð°Ð½Ð½Ð°Ñ Ð¸ Ð½Ðµ Ð³Ð°Ð·Ð¸ÑÐ¾Ð²Ð°Ð½Ð½Ð°Ñ ",
        price: 50,
        weight: "0,5 Ð»",
        image_url: "",
        show: 1,
      },
      {
        id: 94,
        category: "ÑÐ¾Ð»Ð¾Ð´Ð½ÑÐµ-Ð½Ð°Ð¿Ð¸ÑÐºÐ¸",
        title: "Ð¡Ð¾Ðº",
        descr: "(Ð² Ð°ÑÑÐ¾ÑÑÐ¸Ð¼ÐµÐ½ÑÐµ)",
        price: 50,
        weight: "200 Ð¼Ð»",
        image_url: "",
        show: 1,
      },
      {
        id: 95,
        category: "Ð¶Ð°ÑÐµÐ½Ð½ÑÐµ-ÑÐ¾Ð»Ð»Ñ",
        title: "Ð¨ÐµÑ-ÑÐ¾Ð»Ð» Ñ ÐºÐ¾Ð¿ÑÐµÐ½Ð¾Ð¹ ÐºÑÑÐ¸ÑÐµÐ¹",
        descr:
          "ÑÐ¸Ñ,  ÐºÐ¾Ð¿ÑÐµÐ½Ð¾Ðµ ÐºÑÑÐ¸Ð½Ð¾Ðµ ÑÐ¸Ð»Ðµ ,  ÑÐ»Ð¸Ð²Ð¾ÑÐ½ÑÐ¹ ÑÑÑ",
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 96,
        category: "Ð¶Ð°ÑÐµÐ½Ð½ÑÐµ-ÑÐ¾Ð»Ð»Ñ",
        title: "Ð¨ÐµÑ-ÑÐ¾Ð»Ð» Ñ  Ð»Ð¾ÑÐ¾ÑÐµÐ¼",
        descr: "ÑÐ¸Ñ,  Ð½Ð¾ÑÐ²ÐµÐ¶ÑÐºÐ¸Ð¹ Ð»Ð¾ÑÐ¾ÑÑ ,  ÑÐ»Ð¸Ð²Ð¾ÑÐ½ÑÐ¹ ÑÑÑ",
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 97,
        category: "Ð¶Ð°ÑÐµÐ½Ð½ÑÐµ-ÑÐ¾Ð»Ð»Ñ",
        title: "Ð¨ÐµÑ-ÑÐ¾Ð»Ð» Ñ   ÐºÑÐµÐ²ÐµÑÐºÐ°Ð¼Ð¸",
        descr: "ÑÐ¸Ñ,  ÑÐ¸Ð³ÑÐ¾Ð²ÑÐµ ÐºÑÐµÐ²ÐµÑÐºÐ¸ ,  ÑÐ»Ð¸Ð²Ð¾ÑÐ½ÑÐ¹ ÑÑÑ",
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 98,
        category: "Ð¶Ð°ÑÐµÐ½Ð½ÑÐµ-ÑÐ¾Ð»Ð»Ñ",
        title: "Ð¨ÐµÑ-ÑÐ¾Ð»Ð» Ñ  ÑÑÐ½ÑÐ¾Ð¼",
        descr: "ÑÐ¸Ñ,  ÑÑÐ½ÐµÑ ,  ÑÐ»Ð¸Ð²Ð¾ÑÐ½ÑÐ¹ ÑÑÑ",
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 99,
        category: "Ð¶Ð°ÑÐµÐ½Ð½ÑÐµ-ÑÐ¾Ð»Ð»Ñ",
        title: "Ð¨ÐµÑ-ÑÐ¾Ð»Ð» Ñ   ÑÐ½ÐµÐ¶Ð½ÑÐ¼ ÐºÑÐ°Ð±Ð¾Ð¼",
        descr: "ÑÐ¸Ñ,  ÑÐ½ÐµÐ¶Ð½ÑÐ¹ ÐºÑÐ°Ð±,  ÑÐ»Ð¸Ð²Ð¾ÑÐ½ÑÐ¹ ÑÑÑ",
        price: 310,
        weight: "",
        image_url: "",
        show: 1,
      },
      {
        id: 100,
        category: "ÑÐµÑÑ",
        title: "Ð¡ÐµÑ â1",
        descr:
          "Ð¤Ð¸Ð»Ð°Ð´ÐµÐ»ÑÑÐ¸Ñ ÐÐ»Ð°ÑÑÐ¸ÑÐµÑÐºÐ°Ñ - Ð¥Ð¾Ð»Ð¾Ð´Ð½ÑÐ¹ ÑÐ¾Ð»Ð», ÐÐ¾ÑÑÑÐ¸Ð¹ Ñ ÐºÑÐµÐ²ÐµÑÐºÐ¾Ð¹ - ÐÐ°ÑÐµÐ½ÑÐ¹ ÑÐ¾Ð»Ð», Ð¡ ÐºÐ¾Ð¿ÑÐµÐ½Ð¾Ð¹  ÐºÑÑÐ¸ÑÐµÐ¹ - ÐÐ°Ð¿ÐµÑÐµÐ½ÑÐ¹  ÑÐ¾Ð»Ð», Ð² Ð¿Ð¾Ð´Ð°ÑÐ¾Ðº: ÐÐ¸Ð½Ð¸  ÑÐ¾Ð»Ð» Ñ Ð¾Ð³ÑÑÑÐ¾Ð¼",
        price: 1010,
        weight: "26 ÐºÑÑÐ¾ÑÐºÐ¾Ð²",
        image_url: "",
        show: 1,
      },
      {
        id: 101,
        category: "ÑÐµÑÑ",
        title: "Ð¡ÐµÑ â2",
        descr:
          "Ð¤Ð¸Ð»Ð°Ð´ÐµÐ»ÑÑÐ¸Ñ ÐÐ»Ð°ÑÑÐ¸ÑÐµÑÐºÐ°Ñ + Ð¡Ð°ÐºÑÑÐ°, ÐÐ°ÑÑÐ´Ð¾ + Ð¡ÐºÐ°Ð½Ð´Ð¸Ð½Ð°Ð²Ð°,  Ð¡ ÐºÐ¾Ð¿ÑÐµÐ½Ð¾Ð¹  ÐºÑÑÐ¸ÑÐµÐ¹ +  Ð¡ ÑÐ¸Ð³ÑÐ¾Ð²Ð¾Ð¹ ÐºÑÐµÐ²ÐµÑÐºÐ¾Ð¹, Ð² Ð¿Ð¾Ð´Ð°ÑÐ¾Ðº: ÐÐ¸Ð½Ð¸ Ð¶Ð°ÑÐµÐ½ÑÐ¹ ÑÐ¾Ð»Ð» Ñ ÐºÑÑÐ¸ÑÐµÐ¹ Ð¸ ÑÑÑÐ¾Ð¼",
        price: 2070,
        weight: "52 ÐºÑÑÐ¾ÑÐºÐ°",
        image_url: "",
        show: 1,
      },
      {
        id: 102,
        category: "ÑÐµÑÑ",
        title: "Ð¡ÐµÑ â3",
        descr:
          "Ð¤Ð¸Ð»Ð°Ð´ÐµÐ»ÑÑÐ¸Ñ ÐÐ»Ð°ÑÑÐ¸ÑÐµÑÐºÐ°Ñ + Ð¦ÐµÐ·Ð°ÑÑ Ñ ÐºÑÑÐ¸ÑÐµÐ¹ + Ð¡Ð»Ð¸Ð²Ð¾ÑÐ½Ð°Ñ Ñ ÐºÑÐµÐ²ÐµÑÐºÐ¾Ð¹, ÐÐ°ÑÑÐ´Ð¾ + ÐÑÐºÐ¾ + ÐÐ¾ÑÑÑÐ¸Ð¹ Ñ ÐºÑÐµÐ²ÐµÑÐºÐ¾Ð¹, Ð¡  ÐºÑÑÐ¸ÑÐµÐ¹ + ÐÑÐµÐ¼Ð¸ÑÐ¼ + Ð¤ÐµÐ½Ð¸ÐºÑ, Ð² Ð¿Ð¾Ð´Ð°ÑÐ¾Ðº: ÐÐ¸Ð½Ð¸  ÑÐ¾Ð»Ð» Ñ Ð¾Ð³ÑÑÑÐ¾Ð¼, ÐÐ¸Ð½Ð¸ Ð¶Ð°ÑÐµÐ½ÑÐ¹ ÑÐ¾Ð»Ð» Ñ ÐºÑÑÐ¸ÑÐµÐ¹ Ð¸ ÑÑÑÐ¾Ð¼",
        price: 3060,
        weight: "80 ÐºÑÑÐ¾ÑÐºÐ¾Ð²",
        image_url: "",
        show: 1,
      },
    ],
  },
]

export default dataAllProducts
